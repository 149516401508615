import { keyframes, Typography } from "@mui/material";
import React from "react";
import CoffeeIcon from "@mui/icons-material/Coffee";
import styled from "@emotion/styled";

export const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const AnimatedCoffee = styled(CoffeeIcon)({
  animation: "$spin 2s linear infinite",
});

export const LogoWithTitle = (props) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <AnimatedCoffee sx={{ mr: 1, color: "gold" }} />
      <Typography
        variant="h6"
        noWrap
        component="a"
        href="/"
        sx={{
          mr: 2,
          fontFamily: "monospace",
          fontWeight: 700,
          letterSpacing: ".3rem",
          textDecoration: "none",
          color: "gold",
        }}
      >
        sdev
      </Typography>
    </div>
  );
};
