import "global.css";

import { Paper } from "@mui/material";
import { Container } from "@mui/system";
import Header from "components/header";
import React, { lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

const HomePageLazy = lazy(() => import("./Home"));
const VideosPageLazy = lazy(() => import("./pages/videos"));
const SvgExportPageLazy = lazy(() => import("./pages/svg"));
const SlugPageLazy = lazy(() => import("./pages/slug"));
// const BlogPageLazy = lazy(() => import("./pages/blog"));
const ThPageLazy = lazy(() => import("./pages/th"));
const ProfilePageLazy = lazy(() => import("./pages/profile"));
const ContactPageLazy = lazy(() => import("./pages/contact"));
const JxPageLazy = lazy(() => import("./pages/jx"));
const Bib2TxtPageLazy = lazy(() => import("./pages/bib2txt"));

const routes = [
  {
    path: "/home",
    element: <HomePageLazy />,
  },
  {
    path: "/",
    element: <Navigate to="/home" />,
  },
  {
    path: "/jx",
    element: <JxPageLazy />,
  },
  {
    path: "/svg",
    element: <SvgExportPageLazy />,
  },
  {
    path: "/slug",
    element: <SlugPageLazy />,
  },
  {
    path: "/videos",
    element: <VideosPageLazy />,
  },
  // {
  //   path: "/blog",
  //   element: <BlogPageLazy />,
  // },
  {
    path: "/th",
    element: <ThPageLazy />,
  },
  {
    path: "/profile",
    element: <ProfilePageLazy />,
  },
  {
    path: "/contact",
    element: <ContactPageLazy />,
  },
  {
    path: "/bib2txt",
    element: <Bib2TxtPageLazy />,
  },
];
const App = (props) => {
  return (
    <div className="App">
      <Header />
      <Container fixed>
        <Suspense
          fallback={<Paper style={{ minHeight: "80vh" }}>Please wait...</Paper>}
        >
          <Routes>
            {routes.map((route, idx) => {
              return <Route {...route} key={idx} />;
            })}
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </Suspense>
      </Container>
    </div>
  );
};

export default App;
