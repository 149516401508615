import { Box, Button, Grid, Menu, MenuItem } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const MegaMenu = (props) => {
  const { item } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (subItem) => {
    setAnchorEl(null);
    if (subItem?.href) navigate(subItem?.href);
  };

  return (
    <>
      <Button
        aria-controls="mega-menu"
        aria-haspopup="true"
        onClick={handleClick}
        sx={{ color: "#fff" }}
      >
        {item.name}
      </Button>
      <Menu
        id="mega-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          sx: {
            padding: "16px",
          },
        }}
      >
        <Grid container spacing={2}>
          <Grid item>
            {/* <Typography variant="subtitle1">Converter</Typography> */}
            {/* <Box sx={{ mt: 1 }}> */}
            <Box>
              {item.items.map((subItem) => {
                return (
                  <MenuItem key={subItem.name} onClick={(e) => handleClose(subItem)}>
                    {subItem.name}
                  </MenuItem>
                );
              })}
            </Box>
          </Grid>
          {/* <Grid item xs={4}>
            <Typography variant="subtitle1">Category 2</Typography>
            <Box sx={{ mt: 1 }}>
              <MenuItem onClick={handleClose}>Item 2.1</MenuItem>
              <MenuItem onClick={handleClose}>Item 2.2</MenuItem>
              <MenuItem onClick={handleClose}>Item 2.3</MenuItem>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle1">Category 3</Typography>
            <Box sx={{ mt: 1 }}>
              <MenuItem onClick={handleClose}>Item 3.1</MenuItem>
              <MenuItem onClick={handleClose}>Item 3.2</MenuItem>
              <MenuItem onClick={handleClose}>Item 3.3</MenuItem>
            </Box>
          </Grid> */}
        </Grid>
      </Menu>
    </>
  );
};

export default MegaMenu;
