import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import { Container } from "@mui/system";
import * as React from "react";
import { Link } from "react-router-dom";

import { LogoWithTitle } from "./logo";
import MegaMenu from "./megamenu";

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

const drawerWidth = 240;

// const navItems = [
//   { name: "Home", href: "/home" },
//   { name: "Free Videos", href: "/videos" },
//   // { name: "Blog", href: "https://blog.sdev.in", isExternal: true },
//   { name: "JSON to Excel", href: "/jx" },
//   { name: "SVG Export", href: "/svg" },
//   { name: "Slug", href: "/slug" },
//   { name: "Th", href: "/th" },
//   { name: "Profile", href: "/profile" },
//   { name: "Contact", href: "/contact" },
// ];

const navItems = [
  { name: "Home", href: "/home" },
  { name: "Courses", href: "/videos" },
  {
    name: "Tools",
    items: [
      { name: "JSON to Excel", href: "/jx" },
      { name: "SVG Export", href: "/svg" },
      { name: "Slugify", href: "/slug" },
      // { name: "Bib to Txt", href: "/bib2txt" },
    ],
  },
  // { name: "Blog", href: "https://blog.sdev.in", isExternal: true },
  { name: "Th", href: "/th" },
  { name: "Profile", href: "/profile" },
  { name: "Contact", href: "/contact" },
];

export const EachNavItem = ({ item }) => {
  return (
    <ListItem key={item.name} disablePadding>
      <ListItemButton
        component={Link}
        to={item.href}
        sx={{ textAlign: "center" }}
      >
        <ListItemText primary={item.name} />
      </ListItemButton>
    </ListItem>
  );
};
export default function DrawerAppBar(props: Props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <LogoWithTitle />
      <Divider />
      <List>
        {navItems.map((item, idx) => {
          if (item.items)
            return item.items.map((subItem) => (
              <EachNavItem key={subItem.name} item={subItem} />
            ));

          return <EachNavItem key={item.name} item={item} />;
        })}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      <AppBar component="nav">
        <Container fixed>
          <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>
            <LogoWithTitle />
            <Box sx={{ display: { xs: "none", sm: "block" } }}>
              {navItems.map((item, idx) => {
                if (item.items) {
                  return <MegaMenu key={item.name} item={item} />;
                }

                return (
                  <Button
                    key={item.name}
                    component={Link}
                    to={item.href}
                    sx={{ color: "#fff" }}
                  >
                    {item.name}
                  </Button>
                );
              })}
            </Box>
            <Box
              sx={{ display: { xs: "block", sm: "block", md: "none" } }}
            ></Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
          },
        }}
      >
        {drawer}
      </Drawer>
      <Toolbar />
    </>
  );
}
